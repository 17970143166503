import { storeSetup, loanQuery, loanBorrowerTasksQuery } from '~/components/loanOfficerApp/Queries/servicerLoanQueries.gql'
import apolloClient from '~/util/apolloClient'
import generatePermissions from './permissions.js'
import isEmpty from 'lodash/isEmpty'
const { provider } = apolloClient()

export default {
  setBorrowerFromLoanInfo (context, { borrower, type }) {
    if (type === 'BORROWER') {
      context.commit('setBorrower', massageBorrower(borrower, type))
    } else if (type === 'CO_BORROWER') {
      context.commit('setCoborrower', massageBorrower(borrower, type))
    }
  },
  async refetchLoan (context) {
    try {
      context.commit('setLoadingLoan', true)
      const { data } = await provider.clients.defaultClient.query({
        query: loanQuery,
        variables: {
          loanGuid: context.getters.getSelectedLoanGuid
        },
        fetchPolicy: 'network-only'
      })
      if (data.loan) {
        context.commit('setLoanOwner', data.loan.borrower || data.loan.appUser)
        context.dispatch('setBorrowerFromLoanInfo', { borrower: data.loan.borrower, type: 'BORROWER' })
        context.dispatch('setBorrowerFromLoanInfo', { borrower: data.loan.co_borrower, type: 'CO_BORROWER' })
        context.commit('setSelectedLoan', data.loan)
        context.commit('setLoadingLoan', false)
        // Set Loan App Image Url
        const loanApp = data.loan?.user_loan_app || {}
        if (!isEmpty(loanApp)) {
          loanApp.imageUrl = `/servicer_leads/export_loan_app/${context.getters.getServicerId}?format=.pdf&disposition=inline&loan_app_guid=${loanApp.guid}`
        }
      }
    } catch (error) {
      context.commit('setToast', {
        active: true,
        event: 'error',
        message: 'Error reloading loan information',
        description: 'Please refresh the page'
      })
      context.commit('setLoadingLoan', false)
    }
  },
  async refetchTasks (context, { queryForLoan, loanAppGuid = null }) {
    try {
      context.commit('setLoadingTasks', true)
      const { data } = await provider.clients.defaultClient.query({
        query: loanBorrowerTasksQuery,
        variables: {
          queryForLoan: queryForLoan,
          loanGuid: context.getters.getSelectedLoanGuid,
          loanAppGuid: loanAppGuid || context.getters.getSelectedLoanAppGuid
        },
        fetchPolicy: 'network-only'
      })
      if (data.loan) {
        context.commit('setLoanBorrowersForLoan', data.loan.loan_borrowers)
        context.commit('setLoanOfficerTasksForLoan', data.loan.loanOfficerTasks)
        if (data.loan.thirdPartyRecipientsAndTasksList?.length > 0) context.commit('setThirdPartyTasksForLoan', data.loan.thirdPartyRecipientsAndTasksList)
        context.commit('setLoadingTasks', false)
      } else if (context.getters.getPermissions.loMultiBorrowerSupport && data.loan_applications) {
        context.commit('setLoanApplications', data.loan_applications)
        if (data.loan_applications[0].loanOfficerTasks?.length > 0) context.commit('setLoanOfficerTasksForLoanApp', data.loan_applications[0].loanOfficerTasks)
        context.commit('setLoadingTasks', false)
      } else if (data.loan_applications) {
        const loanApp = data.loan_applications.find(la => la.guid === context.getters.getSelectedLoanAppGuid)
        context.commit('setLoanBorrowersForLoanApp', loanApp.loan_borrowers)
        if (loanApp.loanOfficerTasks?.length > 0) context.commit('setLoanOfficerTasksForLoanApp', loanApp.loanOfficerTasks)
        context.commit('setLoadingTasks', false)
      }
    } catch (error) {
      context.commit('setToast', {
        active: true,
        event: 'error',
        message: 'Error reloading task information',
        description: 'Please refresh the page'
      })
      context.commit('setLoadingTasks', false)
    }
  },
  async storeSetup (context, { servicerId, loanGuid, loanAppGuid, queryForLoan }) {
    context.commit('setLoadingStoreSetup', true)
    try {
      const { data } = await provider.clients.defaultClient.query({
        query: storeSetup,
        variables: {
          spId: servicerId,
          loanGuid: loanGuid || '',
          loanAppGuid: loanAppGuid || '',
          queryForLoan: queryForLoan
        }
      })

      // Set Servicer Profile and Team Member
      const servicer = data.servicer_profile
      const teamMember = data.user.team_member
      const effectiveUser = data.user.servicer_profile || data.user.team_member
      context.commit('setServicerProfile', servicer)
      context.commit('setSupportPhone', servicer.effective_company_support_phone)
      context.commit('setTeamMemberGuid', teamMember?.guid)
      context.commit('setTeamMemberId', teamMember?.id)
      context.commit('setLosName', servicer.company?.los_name || '')
      context.commit('setDocStorageLocation', servicer.company?.storage_location?.type)

      // Set Permissions
      const permissions = generatePermissions(servicer, effectiveUser)
      context.commit('updatePermissions', permissions)

      let lb = null
      let au = null
      let b = null
      let clb = null
      let cb = null

      // Set Loan
      if (data.loan) {
        context.commit('setSelectedLoan', data.loan)
        lb = data.loan.borrower
        au = data.loan.borrower?.appUser
        clb = data.loan.co_borrower
        const applications = data.loan.loan_applications || []
        context.commit('setLoanApplications', applications)
        context.commit('setPrimaryLoanApplication', applications[0] || {})
      }

      // Set Loan app
      if (data.loan_applications && data.loan_applications.length > 0) {
        const loanApp = data.loan_applications.find(la => la.guid === loanAppGuid)
        context.commit('setSelectedLoanApp', loanApp)
        lb = loanApp.loanBorrower
        au = loanApp.borrower.appUser
        b = loanApp.borrower
        clb = loanApp.loanCoborrower
        cb = loanApp.co_borrower
        context.commit('setLoanApplications', data.loan_applications)
        context.commit('setPrimaryLoanApplication', data.loan_applications[0])
      }
      // Set Loan Owner
      const owner = lb || au || b
      const coOwner = clb || cb

      context.commit('setLoanOwner', owner)
      context.commit('setLoanOwnerName', owner?.displayName || owner?.fullName || 'Unnamed borrower')
      context.commit('setIsRemoteBorrower', au === null)

      // Set Loan App Image Url
      const primaryLoanApp = data.loan_applications && data.loan_applications.length > 0 ? data.loan_applications[0] : {}
      const loanApp = data.loan?.user_loan_app || primaryLoanApp || {}
      if (!isEmpty(loanApp)) {
        loanApp.imageUrl = `/servicer_leads/export_loan_app/${servicer.id}?format=.pdf&disposition=inline&loan_app_guid=${loanApp.guid}`
      }

      // Set Borrower / Coborrower
      context.dispatch('setBorrowerFromLoanInfo', { borrower: owner, type: 'BORROWER' })
      context.dispatch('setBorrowerFromLoanInfo', { borrower: coOwner, type: 'CO_BORROWER' })
      context.commit('setLoadingStoreSetup', false)
    } catch (error) {
      if (error.message === 'GraphQL error: Item Not Found') {
        context.commit('setLoadingStoreSetup', false)
        context.commit('setToast', {
          active: true,
          type: 'sticky',
          description: 'Contact your administrator to have them assign a new loan officer',
          event: 'error',
          message: 'This loan officer has been deactivated',
          actionText: '',
          actionVariables: {}
        })
      }
    }
  }
}

function massageBorrower (borrower, type) {
  return borrower
    ? {
      id: borrower?.id,
      guid: borrower?.guid,
      creditAuth: borrower?.creditAuth,
      dob: borrower?.dob,
      type: type,
      fullName: `${borrower?.firstName ?? ''} ${borrower?.lastName ?? ''}`.trim(),
      firstName: borrower?.firstName,
      lastName: borrower?.lastName,
      suffix: borrower?.suffix,
      displayName: borrower?.displayName,
      email: borrower?.email ?? '',
      cellPhone: borrower?.cellPhone,
      homePhone: borrower?.homePhone,
      ssn: borrower?.ssn,
      workPhone: borrower?.workPhone,
      primaryBorrower: borrower?.primaryBorrower,
      appUser: borrower?.appUser
    }
    : null
}
