<template>
  <sn-modal
    v-if="content"
    v-model="showDialog"
    persistent
    :title="modalTitle"
    type="decision"
  >
    <template v-if="state === 'chooseAuthMethod'">
      <p
        class="sn-body-1 mb-4"
        v-html="content.select_method_of_authentication_message"
      />
      <sn-radio-group
        v-if="authMethods"
        v-model="chosenAuthMethod"
        class="mb-2"
        :mandatory="true"
      >
        <sn-radio
          v-for="authMethod in authMethods"
          :key="authMethod.value"
          :label="authMethod.value"
          :value="authMethod.auth_method"
        />
      </sn-radio-group>
    </template>
    <template v-else-if="state === 'verify2fa'">
      <p class="sn-body-1 mb-8">
        {{ content.enter_authentication_code_message }}
      </p>
      <sn-text-field
        v-model="code"
        v-mask="'######'"
        class="mb-4"
        :maxlength="6"
        :error="error"
        :error-messages="codeFieldErrorMessage"
        :autofocus="true"
        name="code"
        label="6 digit verification code"
        @keyup.enter="sn_snTwoFactorAuthDialogMixin_validate2fa"
        @paste="clearErrors"
        @focus="clearErrors"
        @blur="validate2faCodeField"
      />
      <div>
        Didn't get a code?
        <sn-btn
          v-if="state === 'verify2fa'"
          :text="content.get_a_new_code_button"
          size="standard"
          type="text"
          class="mr-2"
          :icon-size="24"
          @click="getNewCodeClickHandler"
        />
      </div>
    </template>
    <template v-else-if="state === 'verify_phone'">
      <p class="sn-body-1">
        {{ content.verify_phone_message }}
      </p>
      <sn-text-field
        v-model="phoneNumber"
        v-mask="'(###) ###-####'"
        placeholder="(___) ___-____"
        class="mt-8"
        label="Enter a phone number"
        :error="error"
        :error-messages="phoneFieldErrorMessage"
        @focus="clearErrors"
      />
    </template>
    <template #footer>
      <div class="flex-grow-1 d-flex justify-end">
        <div>
          <sn-btn
            :text="secondaryButtonText"
            size="standard"
            type="secondary"
            class="mr-2"
            @click="cancelButtonClickHandler"
          />
          <sn-btn
            :text="primaryButtonText"
            size="standard"
            :loading="loading"
            @click="primaryButtonClickHandler"
          />
        </div>
      </div>
    </template>
  </sn-modal>
</template>

<script>
import snTwoFactorAuthDialogMixin from '../mixins/snTwoFactorAuthDialogMixin'
import { snBtn, snModal, snTextField } from '@simplenexus/snui'

export default {
  name: 'MyTwoFactorAuthDialog',

  components: {
    snModal,
    snTextField,
    snBtn
  },
  mixins: [snTwoFactorAuthDialogMixin],

  props: {
    eventData: {
      type: Object,
      default: () => {},
      required: false
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      codeFieldErrorMessage: ''
    }
  },

  computed: {
    primaryButtonText () {
      switch (this.state) {
        case 'chooseAuthMethod':
          return this.content.send_code_button
        case 'verify2fa':
          return this.content.verify_code_button
        case 'verify_phone':
          return this.content.verify_phone_button
        default:
          return 'Okay'
      }
    },

    phoneFieldErrorMessage () {
      if (this.phoneNumber && this.phoneNumber.length < 1) {
        return 'We need your phone number in order to proceed'
      } else {
        return this.errorMessages
      }
    },

    secondaryButtonText () {
      if (this.state === 'verify_phone') {
        return this.content.cancel_verify_phone_button
      } else {
        return 'Cancel'
      }
    },

    modalTitle () {
      if (this.state === 'verify_phone') {
        return this.content.verify_phone_title
      } else if (this.state === 'verify2fa') {
        return 'Enter verification code'
      } else {
        return this.content.two_factor_auth_needed_message
      }
    }
  },

  watch: {
    code (val) {
      if (!this.code) {
        this.clearErrors()
      }
    }
  },

  methods: {
    primaryButtonClickHandler () {
      switch (this.state) {
        case 'chooseAuthMethod':
          this.sn_snTwoFactorAuthDialogMixin_sendCode()
          break
        case 'verify2fa':
          this.sn_snTwoFactorAuthDialogMixin_validate2fa()
          break
        case 'verify_phone':
          this.sn_snTwoFactorAuthDialogMixin_verifyPhoneNumber()
      }
    },

    cancelButtonClickHandler () {
      switch (this.state) {
        case 'chooseAuthMethod':
        case 'verify2fa':
          this.showDialog = false
          this.state = 'chooseAuthMethod'
          this.code = ''
          this.clearErrors()
          break
        case 'verify_phone':
          this.sn_snTwoFactorAuthDialogMixin_cancelVerifyPhone()
      }
    },

    getNewCodeClickHandler () {
      this.code = ''
      this.state = 'chooseAuthMethod'
      this.error = false
    },

    clearErrors () {
      this.error = false
      this.errorMessages = []
      this.codeFieldErrorMessage = ''
    },

    validate2faCodeField () {
      if (this.code?.length < 6) {
        this.codeFieldErrorMessage = 'Enter your 6 digit verification code'
      } else {
        this.codeFieldErrorMessage = this.errorMessages
      }
    }
  }

}
</script>
<style lang="scss" scoped>
::v-deep .v-radio label {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  max-width: calc(100% - 40px) !important;
}
</style>
