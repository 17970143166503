import apolloClient from '~/util/apolloClient'
import { markLoanTasksCompleted } from '~/components/loanOfficerApp/dashboard/gql/dashboardMutations.gql'
import LoanAppSidePanel from '~/components/loanOfficerApp/dashboard/tasks/loanApp/LoanAppSidePanel.vue'
import VoaSidePanel from '~/components/loanOfficerApp/dashboard/tasks/voa/VoaSidePanel.vue'
import CreditReportSidePanel from '~/components/loanOfficerApp/dashboard/tasks/credit/CreditReportSidePanel.vue'
import AppraisalSidePanel from '~/components/loanOfficerApp/dashboard/tasks/appraisal/AppraisalSidePanel.vue'
import RunCreditSidePanel from '~/components/loanOfficerApp/dashboard/tasks/credit/RunCreditSidePanel.vue'
import DocumentSidePanel from '~/components/loanOfficerApp/dashboard/tasks/document/DocumentSidePanel.vue'
import DisclosureSidePanel from '~/components/loanOfficerApp/dashboard/tasks/disclosure/DisclosureSidePanel.vue'
import { EVENT_IDS, TASK_OBJECTS } from '../tasks/tasksHelpers'

const { provider } = apolloClient()

export default {
  setLoanData (context, { data }) {
    const milestones = data?.servicer_profile?.default_loan_milestone_definitions ? data.servicer_profile.default_loan_milestone_definitions : []
    context.commit('setMilestones', milestones)

    if (data?.servicer_profile?.loan_total_by_milestone) {
      const filteredLoans = {}
      let totalLoans = 0

      data.servicer_profile.loan_total_by_milestone.forEach(total => {
        filteredLoans[total.milestone_name] = total.milestone_count
        totalLoans += total.milestone_count
      })

      context.commit('setMilestoneFilteredLoanTotals', filteredLoans)
      context.commit('setLoanTotal', totalLoans)
    }

    context.commit('setLoanAppTotal', data?.servicer_profile?.user_loan_app_total)
  },
  setAnalytics (context, servicerProfile) {
    context.commit('setShareAppAnalytics', servicerProfile?.recent_app_shares)
    context.commit('setNewLoanAppAnalytics', servicerProfile?.new_loan_applications)
    context.commit('setUpcomingClosingsCount', servicerProfile?.upcoming_closings_count)
    context.commit('setAbandonedLoanAppAnalytics', servicerProfile?.abandoned_loan_applications)
    context.commit('setNewProspectsAnalytics', servicerProfile?.new_prospects)
  },
  setTasks (context, tasks) {
    const mappedTasks = tasks.map(task => {
      let name = ''
      if (task.loan) {
        name = task.loan.borrower_full_name
      } else if (task.userLoanApp) {
        name = task.userLoanApp.borrower?.full_name || task.userLoanApp.effective_name
      } else if (task.appUser) {
        name = task.appUser.name
      }
      return { ...task, actorName: name }
    })
    context.commit('setTasks', mappedTasks)
  },
  async markTasksComplete (context, { guids, completed, servicerId, teamMemberGuid = null, eventId = null }) {
    try {
      const results = await provider.clients.defaultClient.mutate({
        mutation: markLoanTasksCompleted,
        variables: {
          guids,
          completed,
          servicerId,
          teamMemberGuid
        }
      })
      context.dispatch('displayToast', {
        success: true,
        multiple: guids.length > 1,
        eventId,
        eventType: completed ? 'Completed' : 'Incomplete'
      })
      return results
    } catch (error) {
      console.log(error)
      context.dispatch('displayToast', {
        success: false
      })
    }
  },
  displayToast (context, { success, multiple = false, eventId, eventType }) {
    // get event name
    let content = {}
    if (success) {
      let message = ''
      let descriptionBeginning = ''
      const messageAction = eventType.toLowerCase() === 'completed' ? 'complete' : 'incomplete'
      if (multiple) {
        message = `Multiple tasks marked as ${messageAction}`
        descriptionBeginning = 'Tasks have'
      } else {
        const eventName = eventId ? TASK_OBJECTS[eventId].title : context.getters.getCurrentTask?.taskName
        message = eventName + ' task is ' + messageAction
        descriptionBeginning = 'This task has'
      }
      const description = descriptionBeginning + ' been moved to the "' + eventType + '" tab in "Your tasks" page'
      content = {
        active: true,
        message,
        description,
        event: 'success'
      }
    } else {
      content = context.getters.getErrorToastContent
    }
    context.commit('setToast', content, { root: true })
  },
  openSidePanel (context, task) {
    switch (task.eventId) {
      case EVENT_IDS.LOAN_APP:
        context.commit('setCurrentSidePanel', LoanAppSidePanel)
        break
      case EVENT_IDS.VOA:
        context.commit('setCurrentSidePanel', VoaSidePanel)
        break
      case EVENT_IDS.CREDIT_REPORT:
        context.commit('setCurrentSidePanel', CreditReportSidePanel)
        break
      case EVENT_IDS.APPRAISAL:
        context.commit('setCurrentSidePanel', AppraisalSidePanel)
        break
      case EVENT_IDS.RUN_CREDIT:
        context.commit('setCurrentSidePanel', RunCreditSidePanel)
        break
      case EVENT_IDS.DOCUMENT:
        context.commit('setCurrentSidePanel', DocumentSidePanel)
        break
      case EVENT_IDS.SIGN_DISCLOSURES:
        context.commit('setCurrentSidePanel', DisclosureSidePanel)
        break
      default:
        context.commit('setCurrentSidePanel', null)
        context.commit('setCurrentTask', null)
        return
    }
    const taskName = task?.eventId ? context.getters.getTaskName(task) : 'Title not available'
    task.taskName = taskName
    context.commit('setCurrentTask', task)
    context.commit('setShowSidePanel', true)
  },
  closeSidePanel (context) {
    context.commit('setCurrentSidePanel', null)
    context.commit('setCurrentTask', null)
    context.commit('setShowSidePanel', false)
  },
  setActivityFeedSettings (context, settings) {
    const formattedSettings = settings.map(setting => {
      return {
        ...setting,
        eventId: parseInt(setting.event_id),
        value: setting.value === '1' || setting.value === '2'
      }
    })
    context.commit('setActivityFeedSettings', formattedSettings)
  }
}
