import snCustomSettingService from '~/services/snCustomSettingService.js'
import snValidationsService from '~/services/snValidationsService'

export default {
  data () {
    return {
      authMethods: [],
      chosenAuthMethod: null,
      code: null,
      content: null,
      error: null,
      errorMessages: [],
      phoneNumber: null,
      showDialog: false,
      state: 'chooseAuthMethod',
      store: this.$store,
      submitted: false,
      loading: false
    }
  },

  computed: {
    sn_snTwoFactorAuthDialogMixin_width () {
      if (this.small) {
        return '450px'
      } else if (this.medium) {
        return '700px'
      } else if (this.large) {
        return '880px'
      } else if (this.xlarge) {
        return '1024px'
      } else {
        return '656px'
      }
    }
  },

  watch: {
    value (val) {
      this.showDialog = val
      if (this.showDialog === true) {
        const url = '/api/v1/two_factor_auth/request_authentication'
        this.$axios.get(url).then(resp => {
          this.authMethods = resp.data.auth_methods
        })
      }
    },

    showDialog (val) {
      if (val === false) {
        this.$emit('dialogClosed')
      }
    }
  },

  created () {
    snCustomSettingService.index('two_factor_auth')
      .then(
        (response) => {
          this.content = response.data
        }
      )
      .catch((e) => {
        this.$sentry.captureException(e)
        console.log(e)
      })
  },

  methods: {
    sn_snTwoFactorAuthDialogMixin_cancelVerifyPhone () {
      this.sn_snTwoFactorAuthDialogMixin_closeDialog()
      this.$root.$emit('twoFactorAuthComplete', { status: this.state, eventData: this.eventData })
    },

    sn_snTwoFactorAuthDialogMixin_closeDialog () {
      this.showDialog = false
      this.$emit('dialogClosed')
    },

    sn_snTwoFactorAuthDialogMixin_sendCode () {
      this.loading = true
      this.submitted = true
      const url = '/api/v1/two_factor_auth/send_verification_code'
      this.$axios.post(url, { auth_method: this.chosenAuthMethod })
        .then(
          (response) => {
            if (response.status === 200) {
              this.state = 'verify2fa'
              this.submitted = false
            }
          }
        )
        .catch(
          (error) => {
            this.$sentry.captureException(error)
            console.log(error)
          }
        )
        .finally(
          () => {
            this.loading = false
          }
        )
    },

    sn_snTwoFactorAuthDialogMixin_validate2fa () {
      this.loading = true
      this.submitted = true
      if (!this.code || this.code.length < 1) {
        this.error = true
        this.errorMessages.push('Enter your 6 digit verification code')
        this.submitted = false
        this.loading = false
        return
      }
      const url = '/api/v1/two_factor_auth/authenticate'
      this.$axios.post(url, { code: this.code })
        .then(
          (response) => {
            if (response.status === 200) {
              if (response.data.status === 'authenticated') {
                this.error = false
                this.errorMessages = []
                this.state = response.data.status
                if (this.store) this.store.hasTwoFactorAuthed = true
                if (this.$store) this.$store.hasTwoFactorAuthed = true
                this.submitted = false
                if (response.data.phone_needs_verification === true) {
                  this.state = 'verify_phone'
                  this.phoneNumber = response.data.phone
                } else {
                  this.state = response.data.status
                  this.$root.$emit('twoFactorAuthComplete', { status: this.state, eventData: this.eventData })
                  this.sn_snTwoFactorAuthDialogMixin_closeDialog()
                }
              } else {
                this.error = true
                this.errorMessages.push('Verification code is not valid')
                this.submitted = false
              }
            }
          }
        )
        .catch(
          (error) => {
            this.$sentry.captureException(error)
            console.log(error)
          }
        )
        .finally(
          () => {
            this.loading = false
            this.phoneNumber = ''
          }
        )
    },

    sn_snTwoFactorAuthDialogMixin_verifyPhoneNumber () {
      if (!snValidationsService.phone(this.phoneNumber)) {
        this.error = true
        this.errorMessages.push('Please enter a valid phone number')
        return
      }
      this.loading = true
      this.submitted = true
      const url = '/api/v1/two_factor_auth/verify_phone'
      this.$axios.post(url, { phone: this.phoneNumber })
        .then(
          (response) => {
            if (response.status === 200) {
              if (response.data.status === 'phone number verified') {
                this.error = false
                this.errorMessages = []
                this.submitted = false
                this.state = response.data.status
                this.sn_snTwoFactorAuthDialogMixin_closeDialog()
                this.$root.$emit('twoFactorAuthComplete', { status: this.state, eventData: this.eventData })
              } else {
                this.error = true
                this.errorMessages.push(response.data.status)
                this.submitted = false
              }
            }
          }
        )
        .catch(
          (error) => {
            this.$sentry.captureException(error)
            console.log(error)
          }
        )
        .finally(
          () => {
            this.loading = false
          }
        )
    }

  }
}
