export default {
  getInitials: () => fullName => {
    const [firstName, lastName] = fullName.replace(/\s\s+/g, ' ').split(' ')
    return (firstName[0] + lastName[0]).toUpperCase()
  },
  getServicerProfile (state) {
    return state.servicerProfile
  },
  getServicerEmail (state) {
    return state.servicerProfile?.email
  },
  getServicerFullName (state) {
    return state.servicerProfile?.full_name
  },
  getServicerGuid (state) {
    return state.servicerProfile.guid
  },
  getLoanOwnerFullName (state) {
    return state.loanOwnerName
  },
  getLoanOwner (state) {
    return state.loanOwner
  },
  getLoanOwnerEmail (state) {
    return state.loanOwner?.email
  },
  getSelectedLoanGuid (state) {
    return state.selectedLoan?.guid || null
  },
  getServicerId (state) {
    return state.servicerProfile?.id
  },
  getCurrentUser (state) {
    return state.currentUser
  },
  getCurrentUserAccountType (state) {
    return state.currentUser?.account_type
  },
  getCurrentUserEmail (state) {
    return state.currentUser?.email
  },
  getCurrentUserId (state) {
    return state.currentUser?.id
  },
  getCurrentUserServicerProfileId (state) {
    return state.currentUser?.servicer_profile?.id
  },
  getTeamMemberGuid (state) {
    return state.teamMemberGuid
  },
  getTeamMemberId (state) {
    return state.teamMemberId
  },
  getLoanId (state) {
    return state.selectedLoan?.id
  },
  getIsRemoteBorrower (state) {
    return state.isRemoteBorrower
  },
  getSelectedLoan (state) {
    return state.selectedLoan
  },
  getSelectedLoanApp (state) {
    return state.selectedLoanApp
  },
  getPermissions (state) {
    return state.permissions
  },
  getBorrower (state) {
    return state.borrower
  },
  getCoborrower (state) {
    return state.coBorrower
  },
  getSelectedLoanAppGuid (state) {
    return state.selectedLoanApp?.guid
  },
  getBorrowers (state) {
    return [state.borrower, state.coBorrower].filter(b => b?.fullName)
  },
  getLoadingOverview (state) {
    return state.loadingStoreSetup || state.loadingLoanApplication
  },
  getServicerActivationCode (state) {
    return state.servicerProfile?.default_code?.code
  },
  getLosName (state) {
    return state.losName
  },
  getHasLos (state) {
    // Treat Encore Mortgage (111979) as if they had no LOS
    // They use Calyx without syncing so they need these features
    if (state.servicerProfile?.company?.id === '111979') return false
    return !!state.losName
  },
  getDocStorageLocation (state) {
    return state.docStorageLocation
  },
  toasts (state) {
    return state._toasts
  },
  getHeaderTabs (state) {
    return state.headerTabs
  },
  getRefreshLoanDocs (state) {
    return state.refreshLoanDocs
  },
  getLoadingLoan (state) {
    return state.loadingLoan
  },
  getAllIncompleteTasksCount (state) {
    let taskCount = 0
    const loTasks = state.selectedLoan?.loanOfficerTasks || state.selectedLoanApp?.loanOfficerTasks || []
    taskCount += loTasks.filter(t => !t.completedAt).length || 0
    const thirdPartyTasks = state.selectedLoan?.thirdPartyRecipientsAndTasksList || []
    taskCount += thirdPartyTasks.reduce((acc, recipient) => {
      return (recipient.tasks?.filter(t => !t.completedAt || t.status !== 'Completed').length || 0) + acc
    }, 0)
    const borrowers = state.selectedLoan?.loan_borrowers || state.selectedLoanApp?.loan_borrowers
    if (!borrowers) return 0
    taskCount += borrowers.reduce((acc, lb) => {
      return (lb.tasks?.filter(t => !t.completedAt).length || 0) + acc
    }, 0)
    return taskCount
  },
  getAllIncompleteTasksCount2 (state, getters) {
    let taskCount = 0
    let loTasks = []
    if (state.loanApplications?.length > 0) {
      const tasks = state.loanApplications.map(la => { return la.loanOfficerTasks })
      loTasks = [].concat.apply([], tasks)
    } else {
      loTasks = state.selectedLoan?.loanOfficerTasks
    }

    taskCount += loTasks.filter(t => !t.completedAt).length || 0
    const thirdPartyTasks = state.selectedLoan?.thirdPartyRecipientsAndTasksList || []
    taskCount += thirdPartyTasks.reduce((acc, recipient) => {
      return (recipient.tasks?.filter(t => !t.completedAt || t.status !== 'Completed').length || 0) + acc
    }, 0)
    const borrowers = getters.getAllLoanBorrowers
    if (!borrowers) return 0
    taskCount += borrowers.reduce((acc, lb) => {
      return (lb.tasks?.filter(t => !t.completedAt).length || 0) + acc
    }, 0)
    return taskCount
  },
  getPricingEnabled (state) {
    const obEnabled = state.permissions?.hasObPricing && state.permissions?.obUserSynced
    const pollyEnabled = state.permissions?.hasPollyPricing
    return state.permissions?.pricingEnabled && (obEnabled || pollyEnabled)
  },
  getLoadingTasks (state) {
    return state.loadingTasks
  },
  getLoanApplications: state => {
    return state.loanApplications
  },
  getAllLoanBorrowers: state => {
    if (state.selectedLoan) {
      return state.selectedLoan.loan_borrowers
    }
    const borrowers = state.loanApplications?.map(la => {
      return la.loan_borrowers
    })
    return [].concat.apply([], borrowers)
  },
  getBorrowersForLoanApp: (state) => loanAppGuid => {
    const loanApp = state.loanApplications?.find(la => la.guid === loanAppGuid)
    return loanApp?.loan_borrowers || []
  },
  getLoanAppByLoanBorrowerId: (state) => id => {
    return state.loanApplications.find(la => {
      return la.loan_borrowers.some(lb => lb.id === id)
    })
  },
  getLoanOwner2: (state) => loanAppGuid => {
    let lb = null
    let au = null
    let b = null

    if (state.selectedLoan) {
      lb = state.selectedLoan.borrower
      au = state.selectedLoan.borrower?.appUser
    }
    if (state.loanApplications) {
      state.loanApplications.forEach(la => {
        if (la.guid === loanAppGuid) {
          lb = la.loanBorrower
          au = la.borrower.appUser
          b = la.borrower
        }
      })
    }
    return lb || au || b
  },
  getLoanOwnerFullName2: (state, getters) => loanAppGuid => {
    const owner = getters.getLoanOwner2(loanAppGuid)
    return owner?.fullName || 'Unnamed borrower'
  },
  getLoanAppGuids: state => {
    return state.loanApplications.map(la => la.guid)
  },
  getPrimaryLoanApplication: state => {
    return state.primaryLoanApplication
  },
  getPrimaryLoanAppGuid: state => {
    return state.primaryLoanApplication?.guid || null
  }
}
