<template>
  <sn-side-panel
    :value="value"
    title="Review credit report"
    :primary-btn-props="primaryBtnProps"
    :secondary-btn-props="secondaryBtnProps"
    :loading="$apollo.loading"
    @input="$emit('input', $event)"
    @primary-btn-click="handleBtnClick(true)"
    @secondary-btn-click="handleBtnClick(false)"
  >
    <template #subtitleContent>
      <side-panel-subtitle
        :chip-text="chipText"
        :sub-text="taskCreatedText"
        :completed="taskCompleted"
      />
    </template>
    <loan-info-header :task="task" />
    <div class="credit-body">
      <credit-report-gauge
        :value="getQualifyingScore"
        :range="850"
      />
      <span class="date-text sn-caption sn-text-primary-01">
        {{ reportDate }}
      </span>
      <div>
        <sn-btn
          type="secondary"
          size="standard"
          text="View credit report"
          @click="showCreditPdfViewer = true"
        />
      </div>
      <credit-pdf-viewer
        v-model="showCreditPdfViewer"
        :report="creditReport"
        @input="onCloseReport"
      />
    </div>
  </sn-side-panel>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle.vue'
import LoanInfoHeader from '../LoanInfoHeader.vue'
import CreditReportGauge from './CreditReportGauge.vue'
import { creditReportsQuery } from '~/components/loanOfficerApp/credit/gql/creditQueries.gql'
import CreditPdfViewer from '~/components/loanOfficerApp/credit/creditReport/CreditPdfViewer.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CreditReportSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader,
    CreditReportGauge,
    CreditPdfViewer
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    },
    selectedLoId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      loadingMutation: false,
      showCreditPdfViewer: false,
      creditReport: {}
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    reportDate () {
      if (this.creditReport && this.creditReport.updatedDate) {
        return `${this.$moment(this.creditReport.updatedDate).format('MMMM DD, YYYY')} at ${this.$moment(this.creditReport.updatedDate).format('h:mm A')}`
      }
      return ''
    },
    chipText () {
      return this.task?.resolved_at ? `Completed on ${this.$moment(this.task?.resolved_at).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolved_at).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolved_at
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    },
    getQualifyingScore () {
      if (this.creditReport) {
        return this.creditReport.qualifyingScore
      } else return 0
    }
  },
  methods: {
    ...mapActions('Dashboard', ['markTasksComplete']),
    async handleBtnClick (completed, showToast) {
      this.loadingMutation = true
      const servicerId = this.selectedLoId === '0' ? this.servicerId : this.selectedLoId
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed,
        servicerId: servicerId,
        showToast
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    },
    onCloseReport () {
      this.showLoanDoc = false
      if (!this.taskCompleted) {
        this.handleBtnClick(true, true)
      }
    }
  },
  apollo: {
    creditReport: {
      query: creditReportsQuery,
      variables () {
        return {
          loanGuid: this.task?.loan?.guid || '',
          loanAppGuid: this.task?.userLoanApp?.guid || ''
        }
      },
      update (data) {
        let report = null
        if (data?.creditReports) {
          report = data.creditReports[0]
        }
        return report
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/snui';

.date-text {
  padding-top: 0;
  padding-bottom: 24px;
  display: block;
  text-align: center;
}

.credit-body {
  border-radius: 16px;
  border: 1px solid $sn-gray-03;
  margin: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

</style>
