<template>
  <div>
    <sn-pdf-viewer
      :dialog="value"
      :pdfs="[document]"
      :title="getDocName"
      :subtitle="document.message || getDocumentMessage(document)"
      :actions="actions"
      :icon-buttons="iconButtons"
      @close-dialog="$emit('input', false)"
      @input="$emit('input', false)"
    />
    <sn-modal
      v-if="showCopyDocModal"
      v-model="showCopyDocModal"
      title="Duplicate document?"
      type="decision"
      primary-text="Duplicate"
      :loading="loading"
      @submit="copyDoc"
    >
      <div class="sn-body-1">
        Which loan should we copy this document to
      </div>
      <v-form
        ref="copyForm"
        v-model="copyFormValid"
      >
        <sn-select
          v-model="formattedSelectedLoan"
          :items="formattedLoans"
          placeholder="Select a loan"
          :rules="[v => !isEmpty(v) || 'You need to select a loan first']"
        >
          <template v-slot:selection="formattedSelectedLoan">
            <loan-selection-loan-list-item :loan="formattedSelectedLoan.item" />
          </template>
          <template v-slot:item="formattedLoan">
            <loan-selection-loan-list-item :loan="formattedLoan.item" />
          </template>
        </sn-select>
      </v-form>
    </sn-modal>
    <AcceptRejectLoanDoc
      v-if="showAcceptRejectDocModal"
      :show="showAcceptRejectDocModal"
      :doc="document"
      @error="(err) => { handleError(err) }"
      @status-changed="(doc) => { docStatusUpdated(doc) }"
      @cancel="closeAcceptRejectModal()"
    />
    <sn-modal
      v-if="showMoveDocModal"
      v-model="showMoveDocModal"
      title="Move document?"
      type="decision"
      warning-text="Once moved, this document will no longer be accessible from this loan"
      warning-icon="$snWarning"
      primary-text="Move"
      :loading="loading"
      @submit="moveDoc"
    >
      <div class="sn-body-1">
        Which loan should we move this document to
      </div>
      <v-form
        ref="moveForm"
        v-model="moveFormValid"
      >
        <sn-select
          v-model="formattedSelectedLoan"
          :items="formattedLoans"
          placeholder="Select a loan"
          :rules="[v => !isEmpty(v) || 'You need to select a loan first']"
        >
          <template v-slot:selection="formattedSelectedLoan">
            <LoanSelectionLoanListItem :loan="formattedSelectedLoan.item" />
          </template>
          <template v-slot:item="formattedLoan">
            <LoanSelectionLoanListItem :loan="formattedLoan.item" />
          </template>
        </sn-select>
      </v-form>
    </sn-modal>
    <DeleteLoanDocModal
      v-model="showRemoveDocModal"
      :document="document"
      :borrower-name="currentDocumentOwnerFullName"
      @success="handleRemoveDoc"
    />
    <RenameLoanDocModal
      v-model="showRenameDocModal"
      :loan-doc="document"
      @success="renameDoc"
    />
  </div>
</template>

<script>
import AcceptRejectLoanDoc from './AcceptRejectLoanDoc'
import RenameLoanDocModal from './RenameLoanDocModal'
import DeleteLoanDocModal from './DeleteLoanDocModal'
import LoanSelectionLoanListItem from '~/components/loanOfficerApp/loanDetails/overview/LoanSelection/LoanSelectionLoanListItem'
import { formatAddress } from '~/components/loanOfficerApp/loanDetails/overview/loanOverview/overviewHelpers.js'
import { appUserLoansQuery } from '~/components/loanOfficerApp/Queries/loanQueries.gql'
import { moveLoanDocMutation, copyLoanDocToLoansMutation } from '~/components/loanOfficerApp/Queries/loanDocsQueries.gql'
import usCurrencyFilter from '~/util/usCurrencyFilter'
import { mapGetters, mapMutations } from 'vuex'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'LoanDocPreview',
  components: {
    LoanSelectionLoanListItem,
    AcceptRejectLoanDoc,
    RenameLoanDocModal,
    DeleteLoanDocModal
  },
  filters: { usCurrencyFilter },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    document: {
      type: Object,
      required: true
    },
    folderName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showCopyDocModal: false,
      showAcceptRejectDocModal: false,
      showMoveDocModal: false,
      showRemoveDocModal: false,
      showRenameDocModal: false,
      copyFormValid: false,
      moveFormValid: false,
      formattedSelectedLoan: {},
      formattedLoans: [],
      formValid: false,
      systemDocs: ['Credit Authorization Document', 'Credit Authorization Document.pdf'],
      loading: false,
      docNameOverride: ''
    }
  },
  computed: {
    ...mapGetters({
      getLoanOwnerFullName2: 'getLoanOwnerFullName2',
      selectedLoanGuid: 'getSelectedLoanGuid',
      permissions: 'getPermissions',
      loanOwnerName: 'getLoanOwnerFullName'
    }),
    ...mapGetters('Dashboard', ['getShowSidePanel']),
    currentDocumentOwnerFullName () {
      return this.permissions?.loMultiBorrowerSupport ? this.getLoanOwnerFullName2(this.document.userLoanAppGuid) : this.loanOwnerName
    },
    iconButtons () {
      const buttons = []
      if (!this.document) return buttons

      buttons.push({
        icon: '$snPencil',
        tooltipText: 'Rename',
        onClick: () => { this.showRenameDocModal = true }
      })

      if (!this.getShowSidePanel) {
        if (this.document.status !== 'rejected') {
          buttons.push({
            icon: '$snCopy',
            tooltipText: 'Duplicate',
            onClick: () => { this.showCopyDocModal = true }
          })

          buttons.push({
            icon: '$snDocumentMove',
            tooltipText: 'Move',
            onClick: () => { this.showMoveDocModal = true }
          })
        }
        if (((this.permissions?.allowLoanDocDelete || this.permissions?.isAdmin) && !this.isEconsentDocument)) {
          buttons.push({
            icon: '$snTrash',
            tooltipText: 'Delete',
            onClick: () => { this.showRemoveDocModal = true }
          })
        }
      }
      return buttons
    },
    canAcceptOrRejectDoc () {
      if (this.document?.doc_type === 'econsent') {
        return false
      }
      if (this.systemDocs?.includes(this.document?.name)) {
        return false
      }
      if (this.document?.name.includes('Finicity_VOA-') || this.document?.name.includes('Finicity_VOIE') || this.document?.name.includes('FormFree_VOA-')) {
        return false
      }
      if (!this.document?.loan_doc_definition?.id) {
        return false
      }
      return true
    },
    actions () {
      const actionBtns = []
      if (this.canAcceptOrRejectDoc && this.document?.hasImage) {
        const actionBtnText = this.document.status !== 'rejected' ? 'Reject' : 'Approve'
        actionBtns.push(
          {
            type: 'secondary',
            text: actionBtnText,
            onClick: () => {
              this.showAcceptRejectDocModal = true
            }
          }
        )
      }
      return actionBtns
    },
    isEconsentDocument () {
      return this.document?.doc_type === 'econsent'
    },
    getDocName () {
      return this.docNameOverride || this.document?.name || 'Document'
    }
  },
  methods: {
    ...mapMutations([
      'setToast'
    ]),
    isEmpty,
    closeAcceptRejectModal () {
      this.showAcceptRejectDocModal = false
    },
    docStatusUpdated (doc) {
      this.document.status = doc.status
      this.showAcceptRejectDocModal = false
      this.$emit('doc-status-updated', doc)
    },
    renameDoc (name) {
      this.docNameOverride = name
      this.$emit('refetch-loan-docs')
    },
    copyDoc () {
      this.$refs.copyForm.validate()
      if (!this.copyFormValid) return
      this.loading = true
      this.$apollo.mutate({
        mutation: copyLoanDocToLoansMutation,
        variables: {
          docGuid: this.document.guid,
          loanGuids: [this.formattedSelectedLoan.guid]
        }
      }).then(({ data: { copy_loan_doc_to_loans: { success } } }) => {
        if (success) {
          this.setToast({
            active: true,
            type: this.formattedSelectedLoan.loanUrl ? 'action' : 'simple',
            event: 'success',
            message: `${this.document.name} has been duplicated`,
            description: `This document can be viewed in ${this.formattedSelectedLoan.loanNumber} loan`,
            actionText: 'View',
            actionVariables: {
              loanUrl: this.formattedSelectedLoan.loanUrl
            }
          })
        } else {
          this.setToast({
            active: true,
            event: 'error',
            type: 'sticky',
            message: `Sorry, failed to duplicate ${this.document.name}`,
            description: `${this.folderName} task does not exist in ${this.formattedSelectedLoan.loanNumber} loan. Create a task with the same name in ${this.formattedSelectedLoan.loanNumber} loan and try again.`
          })
        }
        this.showCopyDocModal = false
        this.loading = false
        this.formattedSelectedLoan = {}
        this.$emit('input', false)
      }).catch(error => {
        console.log(error)
        this.setToast({
          active: true,
          event: 'error',
          message: `Sorry, failed to duplicate ${this.document.name}`,
          description: 'Please try again or contact support'
        })
        this.showCopyDocModal = false
        this.loading = false
        this.formattedSelectedLoan = {}
        this.$emit('input', false)
      })
    },
    moveDoc () {
      this.$refs.moveForm.validate()
      if (!this.moveFormValid) return
      this.loading = true
      this.$apollo.mutate({
        mutation: moveLoanDocMutation,
        variables: {
          docGuid: this.document.guid,
          newOwnerGuid: this.formattedSelectedLoan.guid
        }
      }).then(({ data }) => {
        this.setToast({
          active: true,
          type: this.formattedSelectedLoan.loanUrl ? 'action' : 'simple',
          event: 'success',
          message: `${this.document.name} has been moved`,
          description: `This document can be viewed in ${this.formattedSelectedLoan.loanNumber} loan`,
          actionText: 'View',
          actionVariables: {
            loanUrl: this.formattedSelectedLoan.loanUrl
          }
        })
        this.showMoveDocModal = false
        this.formattedSelectedLoan = {}
        this.loading = false
        this.$emit('refetch-loan-docs')
        this.$emit('input', false)
      }).catch(error => {
        console.log(error)
        this.setToast({
          active: true,
          event: 'error',
          message: `Sorry, failed to move ${this.document.name}`,
          description: 'Please try again or contact support'
        })
        this.loading = false
        this.showMoveDocModal = false
      })
    },
    handleRemoveDoc () {
      this.$emit('refetch-loan-docs')
      this.showMoveDocModal = false
      this.$emit('input', false)
    },
    formatLoan (loan) {
      const address2 = formatAddress(loan.loanProperty)
      return {
        id: loan.id,
        guid: loan.guid,
        loanNumber: loan.loanNumber,
        loanAmount: loan.loanAmount ? this.$options.filters.usCurrencyFilter(loan.loanAmount) : '',
        status: loan.active ? 'Active' : 'Inactive',
        address: loan.loanProperty && loan.loanProperty.street ? loan.loanProperty.street : '',
        address2,
        loanUrl: this.formatLoanUrl(loan.loanUrl)
      }
    },
    handleError (err) {
      this.$emit('error', err)
    },
    formatLoanUrl (loanUrl) {
      const [path, queryString] = loanUrl.split('?')
      return `${path}/documents?${queryString}`
    },
    getDocumentMessage (doc) {
      const status = doc.status || doc.folder_status
      if (status === 'complete') {
        if (doc.uploaded_by_formatted) {
          return `Uploaded by ${doc.uploaded_by_formatted} on ${this.$moment(doc.uploaded_at).format('MMM DD, YYYY')}`
        } else {
          return ''
        }
      }
      if (status === 'rejected') return 'Rejected, needs to be resubmitted'
      if (status === 'los-import' && doc.hasImage) {
        if (doc.uploaded_by_formatted) {
          return `Uploaded by ${doc.uploaded_by_formatted} on ${this.$moment(doc.uploaded_at).format('MMM DD, YYYY')}`
        } else if (doc.uploaded_by?.full_name) {
          return `Uploaded by ${doc.uploaded_by.full_name} on ${this.$moment(doc.uploaded_at).format('MMM DD, YYYY')}`
        } else return ''
      }
      if (status === 'awaiting_borrower_view') return 'Needs to be viewed by borrower'
      if (status === 'incomplete' && doc.hasImage) {
        return 'Needs to be accepted'
      } else {
        return 'Needs to be uploaded'
      }
    }
  },
  apollo: {
    appUser: {
      query: appUserLoansQuery,
      variables () {
        return {
          appUserId: this.document?.appUser?.id || this.document?.app_user?.id
        }
      },
      skip () {
        return (!this.document?.appUser?.id && !this.document?.app_user?.id) ||
          (!this.showMoveDocModal && !this.showCopyDocModal)
      },
      result ({ data: { appUser: { loans } } }) {
        this.formattedLoans = loans ? loans.reduce((result, loan) => {
          if ((this.selectedLoanGuid !== loan.guid) && (this.document?.loan?.guid !== loan.guid)) {
            const formattedLoan = this.formatLoan(loan)
            result.push(formattedLoan)
          }
          return result
        }, []) : []
      }
    }
  }
}
</script>
