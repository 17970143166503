export default {
  setHasCoborrower (state, payload) {
    state.hasCoborrower = payload
  },
  setShowCreditAuthMissingFields (state, payload) {
    state.showCreditAuthMissingFields = payload
  },
  setShowShareInfoMissingFields (state, payload) {
    state.showShareInfoMissingFields = payload
  },
  setShowEconsentMissingFields (state, payload) {
    state.showEconsentMissingFields = payload
  },
  setSaveError (state, payload) {
    state.saveError = {
      error: payload.error,
      errorMessage: payload.errorMessage
    }
  },
  setIsSaving (state, payload) {
    state.isSaving = payload
  },
  setIsSubmitting (state, payload) {
    state.isSubmitting = payload
  },
  setValues (state, payload) {
    state.lo1003Form.values = payload
  },
  setFocusedSkeletonField (state, payload) {
    state.focusedSkeletonField = payload
  },
  setFocusedField (state, payload) {
    state.focusedField = payload
  },
  setFocusedPage (state, payload) {
    state.focusedPage = payload
  },
  setFieldToScrollTo (state, val) {
    state.fieldToScrollTo = val
  },
  setForm (state, payload) {
    state.lo1003Form = payload
  },
  setCurrentPageIndex (state, payload) {
    state.currentPageIndex = payload
  },
  setServicerActivationCode (state, payload) {
    state.servicerActivationCode = payload
  },
  addToInvalidFields (state, payload) {
    if (!state.invalidFields.includes(payload)) {
      state.invalidFields.push(payload)
    }
  },
  removeFromInvalidFields (state, payload) {
    state.invalidFields.splice(payload, 1)
  },
  addToVisibleFields (state, payload) {
    if (!state.visibleFields.includes(payload)) {
      state.visibleFields.push(payload)
    }
  },
  removeFromVisibleFields (state, payload) {
    const index = state.visibleFields.indexOf(payload)
    if (index >= 0) {
      state.visibleFields.splice(index, 1)
    }
  },
  resetVisibleFields (state) {
    state.visibleFields = []
  },
  clearInvalidFields (state) {
    state.invalidFields = []
  },
  updateValue (state, payload) {
    state.lo1003Form.values[payload.key] = payload.value
  },
  removeFormValue (state, payload) {
    if (payload.key in state.lo1003Form.values) {
      const newVals = {
        ...state.lo1003Form.values
      }
      delete newVals[payload.key]
      state.lo1003Form.values = newVals
    }
  },
  setRedirectQueryParam (state, payload) {
    state.redirectQueryParam = payload
  },
  validateSection (state, payload) {
    state.validateSection = payload
  },
  setShouldScrollToError (state, payload) {
    state.shouldScrollToError = payload
  },
  setNavAction (state, navAction) {
    state.navAction = navAction
  },
  setValuesWereSaved (state, payload) {
    state.valuesWereSaved = payload
  },
  setValuesHaveChanged (state, valuesHaveChanged) {
    state.valuesHaveChanged = valuesHaveChanged
  },
  setLoanApp (state, payload) {
    state.loanApp = payload
  },
  setSelectedService (state, service) {
    state.selectedService = service
  },
  setExpandedNavSection (state, section) {
    state.expandedNavSection = section
  },
  setInMobileView (state, inMobileView) {
    state.inMobileView = inMobileView
  },
  setInMobileApp (state, payload) {
    state.inMobileApp = payload
  },
  setPageIsLoading (state, payload) {
    state.pageIsLoading = payload
  },
  setServicerProfile (state, payload) {
    state.servicerProfile = payload
  },
  setShowNotesSidePanel (state, payload) {
    state.showNotesSidePanel = payload
  },
  setNoteValue (state, payload) {
    state.noteValue = payload
    state.unsavedNoteValue = ''
  },
  setUnsavedNoteValue (state, payload) {
    state.unsavedNoteValue = payload
  },
  setShowChatSidePanel (state, payload) {
    state.showChatSidePanel = payload
  },
  hideMobileFooter (state) {
    state.hideMobileFooter = true
  },
  setOrgAdminEditAllowed (state, payload) {
    state.orgAdminEditAllowed = payload
  },
  setCurrentSearchResults (state, results) {
    state.currentSearchResults = results
  },
  setIsOrderAusModalOpen (state, payload) {
    state.isOrderAusModalOpen = payload
  },
  setShowRunServiceModal (state, payload) {
    state.showRunServiceModal = payload
  },
  setMobileFocusedField (state, payload) {
    state.mobileFocusedField = payload
  },
  setAusProvider (state, payload) {
    state.ausProvider = payload
  },
  setLoanApplications (state, payload) {
    state.loanApplications = payload
  }
}
