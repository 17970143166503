import axios from '~/util/axios'
import { query } from './graphqlService.js'

const customFormRequestFieldsFragment = `
  fragment customFormRequestFields on CustomFormRequest {
    guid
    form_type
    active
    completed
    completed_at
    completed_values_file
    completed_loan_doc {
      id
      name
      image_url
    }
    formDef: form_def_json
    proxy_id
    owner_name
  }
`

const disclosureAssignmentFieldsFragment = `
  fragment disclosureAssignmentFields on DisclosureAssignment {
    name
    status
    action_type
    due_at
    completed
    completed_at
    started
    guid
    current_state
    disclosure_package {
      guid
    }
  }
`

export default {
  chat_contacts (contactsSearch) {
    const params = `
      query chat_contacts ($first: Int $after: String $last: Int $before: String $search: String) {
        chatContacts(first: $first after: $after last: $last before: $before search: $search) {
          edges {
            cursor
            node {
              guid
              fullName
              profileImageUrl
              contactTypeFormatted
              email
              isMe
            }
          }
        }
      }
    `

    const variables = {
      first: 50,
      search: contactsSearch
    }

    return query(params, variables)
  },

  getServicerProfile (userId) {
    const params = `
      query user ($id: ID!){
        user (id: $id){
          servicer_profile {
              id
              guid
          }
        }
      }
      `

    const variables = {
      id: userId
    }

    return query(params, variables)
  },
  create (newUser, code) {
    return axios.post('/api/v1/users', {
      user: {
        name: newUser.firstName,
        last_name: newUser.lastName,
        email: newUser.email,
        unformatted_phone: newUser.phone,
        password: newUser.password,
        password_confirmation: newUser.passwordConfirmation
      },
      code: code
    })
  },
  show () {
    return axios.get('/api/v1/users')
  },
  update (user) {
    if (user.password === null || user.password === '') { delete user.password }
    if (user.passwordConfirmation === null || user.passwordConfirmation === '') { delete user.passwordConfirmation }

    return axios.put('/api/v1/users', {
      user: user
    })
  },

  sendWelcomeEmails (userIds) {
    return axios.post('/users/send_welcome_emails.json',
      {
        users: userIds
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  sendPasswordResetEmail (id) {
    return axios.post(`/users/send_password_reset_email/${id}`)
  },
  sendPushNotifications (orgType, orgID, subject, message, details, action, userIds) {
    let endpoint = ''
    if (orgType === 'company') endpoint = `/companies/send_push_to_all_los/${orgID}`
    else if (orgType === 'region') endpoint = `/regions/send_push_to_all_los/${orgID}`
    else endpoint = `/branches/send_push_to_all_los/${orgID}`
    return axios.get(endpoint,
      {
        params: {
          user_ids: userIds,
          push_title: subject,
          push_message: message,
          push_call_to_action: action,
          detail_data: details
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  becomeUser (userId, sac = null) {
    let url = `/admin/become/${userId}`
    if (sac) {
      url += `?sac=${sac}`
    }
    return axios.post(url,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  enableUsers (userIds) {
    return axios.post('/users/enable',
      {
        users: userIds
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  enablePartners (sacIds) {
    return axios.get('/partners/enable',
      {
        params: {
          ids: sacIds
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  deleteUser (userId) {
    return axios.post('/users/delete.json',
      {
        id: userId
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  disableUsers (spForPartners, spForAppUsers, userIds) {
    return axios.post('/users/disable',
      {
        users: userIds,
        sp_id_for_partners: spForPartners,
        sp_id_for_app_users: spForAppUsers
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },
  moveUsers (companyId, regionId, branchId, userIds) {
    const body = {
      c: companyId,
      r: regionId,
      b: branchId,
      users: userIds
    }
    return axios.patch(`/companies/${companyId}/move_users`, body)
  },

  getCustomFormRequests (userId) {
    const params = `
      query user ($id: ID!){
        user (id: $id){
          is_two_factor_authenticated
          app_user {
            total_active_loans
            custom_form_requests {
              ...customFormRequestFields
            }
          }
        }
      }

      ${customFormRequestFieldsFragment}
    `

    const variables = {
      id: userId + ''
    }

    return query(params, variables, 'network-only')
  },

  getIncompleteDisclosureAssignments (userId) {
    const params = `
      query user ($id: ID!){
        user (id: $id){
          is_two_factor_authenticated
          disclosure_assignments (finalized: false, order: "created_at DESC") {
            name
            status
            action_type
            created_at
            due_at
            completed
            started
            guid
            user_can_deactivate
            assignee_email
            disclosure_package {
              borrower_names
              allow_admins_to_sign_disclosures_for_servicer
            }
          }
        }
      }
    `

    const variables = {
      id: userId + ''
    }

    return query(params, variables, 'network-only')
  },

  getUrgentAssignments (userId) {
    const params = `
      query user ($id: ID!){
        user (id: $id){
          is_two_factor_authenticated
          app_user {
            total_active_loans
            custom_form_requests {
              ...customFormRequestFields
            }
          }
          disclosure_assignments {
            ...disclosureAssignmentFields
          }
        }
      }

      ${customFormRequestFieldsFragment}
      ${disclosureAssignmentFieldsFragment}
    `

    const variables = {
      id: userId + ''
    }

    return query(params, variables, 'network-only')
  },

  getEntityRedirect (assignmentGuid, indicateStarted) {
    return axios.get(`/api/v1/assignments/${assignmentGuid}/entity?return_url=${btoa(window.location.href)}&indicate_started=${indicateStarted}`)
  },

  get2faStatus () {
    return new Promise((resolve, reject) => {
      const params = `
      query user {
        user {
          guid
          is_two_factor_authenticated
          company {
            guid
            require_disclosures_2fa
            require_form_request_2fa
          }
        }
      }
    `
      resolve(query(params, {}, 'network-only'))
    })
  }
}
