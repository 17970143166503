import helperMethods from './Lo1003HelperMethods.js'
import { serviceEnums } from '~/components/loanOfficerApp/lo1003/utilities/enums.js'

export default {
  findAllDynamicAddFieldKeys: (state, getters) => (searchKey) => {
    searchKey = searchKey.includes('INDEX') ? searchKey.replace('INDEX', '') : searchKey
    const keys = Object.keys(getters.getFormValues)
    const keysFound = []
    keys.forEach(key => {
      if (key.includes(searchKey)) {
        keysFound.push(key)
      }
    })
    return keysFound
  },
  getSaveError: state => {
    return state.saveError
  },
  getValuesWereSaved: state => {
    return state.valuesWereSaved
  },
  getIsSaving: state => {
    return state.isSaving
  },
  getIsSubmitting: state => {
    return state.isSubmitting
  },
  getFormValues: state => {
    return state.lo1003Form.values
  },
  getFocusedSkeletonField: state => {
    return state.focusedSkeletonField
  },
  getFocusedField: state => {
    return state.focusedField
  },
  getFocusedPage: state => {
    return state.focusedPage
  },
  sections: state => {
    return helperMethods.getSections(state.lo1003Form.structure, state.hasCoborrower)
  },
  getCurrentPageIndex: state => {
    return state.currentPageIndex
  },
  getCurrentPage: state => {
    return state?.lo1003Form?.structure[state.currentPageIndex] ?? {}
  },
  showNextButton: (state, getters) => {
    return state.currentPageIndex < getters.getIndexOfLastPage
  },
  showBackButton: (state, getters) => {
    return state.currentPageIndex > getters.getIndexOfFirstPage
  },
  fieldFromKey: (state) => (key) => {
    let searchKey = key
    if (key.match(/_\d+/) && (key !== 'vom_mortgage_payment_1')) {
      searchKey = searchKey.replace(/_\d+/, '_INDEX')
    }
    const fields = state.lo1003Form.fields.filter((x) => { return x.key === searchKey })
    return fields[0]
  },
  fieldDisabled: (state, getters) => (key) => {
    return 'editable' in getters.fieldFromKey(key) ? !getters.fieldFromKey(key).editable : false
  },
  isInvalidField: (state, getters) => (field) => {
    return getters.getInvalidFields.includes(field)
  },
  isFieldMissing: (state, getters) => (fieldKey) => {
    var fieldValue = getters.getFormValues[fieldKey]
    return [undefined, null, ''].includes(fieldValue)
  },
  getIndexOfLastPage: (state) => {
    const sections = helperMethods.getSections(state.lo1003Form.structure)
    return sections.slice(-1)[0]?.pages.slice(-1)[0].pageIndex ?? -1
  },
  getIndexOfFirstPage: (state) => {
    const sections = helperMethods.getSections(state.lo1003Form.structure)
    return sections[0]?.pages[0].pageIndex ?? -1
  },
  getNextPageIndex: (state, getters) => {
    if (state.currentPageIndex < getters.getIndexOfLastPage) {
      if (!state.hasCoborrower && getters.getCurrentPage.key === 'co_borrower-coborrower_info__overview') {
        return state?.lo1003Form?.structure?.filter(s => s.key === 'borrower-assets_liabilities__real_estate')[0].pageIndex
      }
      return state.currentPageIndex + 1
    } else {
      return state.currentPageIndex
    }
  },
  goBackPageIndex: (state, getters) => {
    if (state.currentPageIndex > getters.getIndexOfFirstPage) {
      if (!state.hasCoborrower && getters.getCurrentPage.key === 'borrower-assets_liabilities__real_estate') {
        return state?.lo1003Form?.structure?.filter(s => s.key === 'co_borrower-coborrower_info__overview')[0].pageIndex
      }
      return state.currentPageIndex - 1
    } else {
      return state.currentPageIndex
    }
  },
  getNextPageTitle: (state, getters) => {
    const pageIndex = getters.getNextPageIndex
    const page = state.lo1003Form.structure[pageIndex]
    return page.title
  },
  getHasCoborrower: (state) => {
    return state.hasCoborrower
  },
  getInvalidFields: state => {
    return state.invalidFields
  },
  getBorrower: (state, getters) => {
    let name = 'Unnamed Borrower'
    let email
    let phone
    let appUserGuid = ''
    const firstName = getters.getFormValues.first_name || ''
    const lastName = getters.getFormValues.last_name || ''
    if (getters.getFormValues.first_name) {
      name = getters.getFormValues.first_name
      if (getters.getFormValues.last_name) {
        name += ' ' + getters.getFormValues.last_name
      }
    }
    if (getters.getFormValues.email) {
      email = getters.getFormValues.email
    }
    if (getters.getFormValues.borrower_cell_phone) {
      phone = getters.getFormValues.borrower_cell_phone
    }
    if (getters.getLoanApp.app_user?.guid) {
      appUserGuid = getters.getLoanApp.app_user.guid
    }
    return {
      name,
      email,
      phone,
      appUserGuid,
      type: 'BORROWER',
      firstName,
      lastName
    }
  },
  getCoBorrower: (state, getters) => {
    let name = 'Unnamed Borrower'
    let email
    let phone
    if (getters.getFormValues.coborrower_first_name) {
      name = getters.getFormValues.coborrower_first_name
      if (getters.getFormValues.coborrower_last_name) {
        name += ' ' + getters.getFormValues.coborrower_last_name
      }
    }
    if (getters.getFormValues.coborrower_email) {
      email = getters.getFormValues.coborrower_email
    }
    if (getters.getFormValues.coborrower_cell_phone) {
      phone = getters.getFormValues.coborrower_cell_phone
    }
    return {
      name,
      email,
      phone,
      type: 'CO_BORROWER'
    }
  },
  getLo1003Form: state => {
    return state.lo1003Form
  },
  getInMobileView: state => {
    return state.inMobileView
  },
  getInMobileApp: state => {
    return state.inMobileApp
  },
  isReviewPage: (state, getters) => {
    return getters.getCurrentPage.key.includes('review')
  },
  getVisibleFields: state => {
    return state.visibleFields
  },
  fieldSkippable: (state, getters) => (key) => {
    let skip = null
    if (getters.getLo1003Form.initially_skip_fields !== undefined &&
      getters.getLo1003Form.initially_skip_fields !== null &&
      getters.getLo1003Form.initially_skip_fields.indexOf(key) !== -1) {
      skip = true
      getters.getLo1003Form.initially_skip_fields.splice(getters.getLo1003Form.initially_skip_fields.indexOf(key), 1)
    } else {
      skip = false
    }
    return skip
  },
  getParentSection: (state, getters) => {
    return getters.sections.find(s => s.name === getters.getCurrentPage.step)
  },
  getServicerProfile: state => {
    return state.servicerProfile
  },
  getServicerActivationCode: state => {
    return state.servicerActivationCode
  },
  getIsLoanAppLocked: state => {
    return state.isLoanAppLocked
  },
  getIsCurrentSectionValid: (state, getters) => {
    let allSectionsValid = true
    getters.getCurrentPage.fields.forEach((f) => {
      if (getters.isInvalidField(f)) {
        allSectionsValid = false
        // break out of foreach
        return false
      }
    })
    return allSectionsValid
  },
  evalOperator: (state) => (op, condition) => {
    const input = state.lo1003Form.values[op[0]]
    const options = {
      in: (input, condition) => {
        if (Array.isArray(condition)) {
          if (Array.isArray(input)) {
            return condition.filter(value => input.includes(value)).length > 0
          } else {
            return input && condition.indexOf(input) !== -1
          }
        }
      },
      gt: (input, condition) => {
        // greater than
        // parse int is necessary because all values are stored in formDef.values as strings
        return input && parseFloat(input) > condition
      },
      gte: (input, condition) => {
        // greater than equal to
        return input && parseFloat(input) >= condition
      },
      lt: (input, condition) => {
        // less than
        return input && parseFloat(input) < condition
      },
      lte: (input, condition) => {
        // less than equal to
        return input && parseFloat(input) <= condition
      },
      contains: (input, condition) => {
        return input && input.includes(condition)
      },
      not: (input, condition) => {
        if (Array.isArray(condition)) {
          if (Array.isArray(input)) {
            return condition.filter(value => input.includes(value)).length === 0
          } else {
            return input && condition.indexOf(input) === -1
          }
        } else {
          return input !== (typeof condition === 'string' ? condition : condition.toString())
        }
      },
      present: (input, condition) => {
        return !!(typeof input === 'boolean' || (input && input.length > 0)) === condition // Account for when the desired condition is false, e.g., we DON'T want it to be present
      },
      input: (input, condition) => {
        return input
      },
      // after and before check length of 10 because that is the length of a
      // completely filled out date field
      after: (input, condition) => {
        return input && input.length === 10 && this.dateComparison(
          new Date(this.$moment(input, 'MM-DD-YYYY').toISOString()),
          helperMethods.parseDateCondition(condition),
          (op1, op2) => op1 > op2
        )
      },
      before: (input, condition) => {
        return input && input.length === 10 && this.dateComparison(
          new Date(this.$moment(input, 'MM-DD-YYYY').toISOString()),
          helperMethods.parseDateCondition(condition),
          (op1, op2) => op1 < op2
        )
      },
      default: (input, condition) => {
        return true
      }
    }
    return (options[op[1]] || options.default)(input, condition)
  },
  getConditionalValue: (state, getters) => (object) => {
    if (object === true || object === undefined || object === null) return true

    if (object instanceof Array) {
      return object.some((element) => getters.getConditionalValue(element))
    } else {
      return Object.entries(object).every(([key, value]) => {
        const op = key.split('__')
        if (op.length > 1) {
          return getters.evalOperator(op, value)
        } else {
          return state.lo1003Form.values[key] === value || !!+state.lo1003Form.values[key] === value
        }
      })
    }
  },
  getValidateSection: state => {
    return state.validateSection
  },
  getShouldScrollToError: state => {
    return state.shouldScrollToError
  },
  getFieldToScrollTo: state => {
    return state.fieldToScrollTo
  },
  getNavAction: state => {
    return state.navAction
  },
  getValuesHaveChanged: state => {
    return state.valuesHaveChanged
  },
  getLoanApp: state => {
    return state.loanApp
  },
  dynamicFieldMaxPagesToAdd: (state, getters) => (field) => {
    const allIndexes = field.template.fields.flatMap(nestedField => {
      const regex = new RegExp(`^${nestedField.key.replace('INDEX', '(\\d+)')}$`)
      return Object.keys(getters.getFormValues).reduce((list, key) => {
        const index = key.match(regex)?.[1]
        if (index) list.push(parseInt(index))
        return list
      }, [])
    })
    return Math.max(...allIndexes)
  },
  calculateDynamicMissingFields: (state, getters) => (field) => {
    const missingDynamicFields = new Set()
    field.template.fields.forEach(nestedField => {
      if (nestedField?.computed?.required && nestedField.computed.required[state.selectedService]) {
        for (let i = field.starting_index || 1; i <= getters.dynamicFieldMaxPagesToAdd(field); i++) {
          const replaceIndexKeyName = nestedField.key.replace('INDEX', i)
          if (getters.getMissingRequiredFields?.includes(replaceIndexKeyName)) {
            missingDynamicFields.add(replaceIndexKeyName)
          }
        }
      }
    })
    return missingDynamicFields
  },
  replaceIndexConditions: (state) => (conditions, index) => {
    return Object.keys(conditions).reduce((acc, key) => {
      const newKey = key.replace('INDEX', index)
      acc[newKey] = conditions[key]
      return acc
    }, {})
  },
  getMissingRequiredFields: (state, getters) => {
    const missingFields = new Set()
    if (state.selectedService !== '' && !getters.getIsLoanAppLocked) {
      getters.getLo1003Form.structure.forEach(item => {
        item.fields.forEach(key => {
          // handle co-borrower fields if there is a co-borrower
          if (!item.key.startsWith('co_borrower-') || getters.getHasCoborrower) {
            if (key.includes('dynamic_add')) {
              const field = getters.fieldFromKey(key)
              const maxPagesToAdd = getters.dynamicFieldMaxPagesToAdd(field)
              if (maxPagesToAdd > 0) {
                field.template.fields.forEach(nestedField => {
                  if (nestedField?.computed?.required && nestedField.computed.required[state.selectedService]) {
                    for (let i = field.starting_index || 1; i <= maxPagesToAdd; i++) {
                      const isConditional = nestedField.computed?.condition !== undefined
                      let fieldConditions = nestedField.computed.condition
                      if (isConditional) fieldConditions = getters.replaceIndexConditions(nestedField?.computed?.condition, i)

                      let requiredConditions = nestedField.computed?.required[state.selectedService]
                      if (requiredConditions !== true) requiredConditions = getters.replaceIndexConditions(nestedField.computed?.required[state.selectedService], i)

                      const replaceIndexKey = nestedField.key.replace('INDEX', i)
                      const isMissingValue = getters.isFieldMissing(replaceIndexKey)
                      if (isMissingValue && (!isConditional || getters.getConditionalValue(fieldConditions)) && getters.getConditionalValue(requiredConditions)) {
                        missingFields.add(replaceIndexKey)
                      }
                    }
                  }
                })
              }
            } else if (getters.isFieldMissing(key)) {
              const field = getters.fieldFromKey(key)
              if (field?.computed?.required && field.computed.required[state.selectedService]) {
                const isConditional = field.computed?.condition !== undefined
                if ((!isConditional || getters.getConditionalValue(field.computed.condition)) && getters.getConditionalValue(field.computed.required[state.selectedService])) {
                  missingFields.add(key)
                }
              }
            }
          }
        })
      })
    }
    return Array.from(missingFields)
  },
  getSelectedService: state => {
    return state.selectedService
  },
  getExpandedNavSection: state => {
    return state.expandedNavSection
  },
  getPageIsLoading: state => {
    return state.pageIsLoading
  },
  getCanRunCredit: (state, getters) => {
    if (getters.getLoanApp?.loan_id) {
      return state.servicerProfile?.feature_setting?.order_credit_enabled_for_loan
    } else {
      return state.servicerProfile?.feature_setting?.order_credit_enabled_for_loan_app
    }
  },
  getCanRunAus: state => {
    return state.servicerProfile?.aus_setting?.aus_enabled &&
          (state.servicerProfile?.has_du || state.servicerProfile?.has_lpa) &&
          !state.loanApp?.status?.imported
  },
  getHasDu: state => {
    return state.servicerProfile?.has_du
  },
  getHasLpa: state => {
    return state.servicerProfile?.has_lpa
  },
  getCanGeneratePreApprovalLetter: (state, getters) => {
    if (getters.getLoanApp?.loan_id) {
      return state.servicerProfile?.has_pre_approval_on_loans
    } else {
      return state.servicerProfile?.has_pre_approval_on_loan_apps
    }
  },
  getCanGeneratePreQualLetter: (state, getters) => {
    if (getters.getLoanApp?.loan_id) {
      return state.servicerProfile?.has_prequal_on_loans
    } else {
      return state.servicerProfile?.has_prequal_on_loan_apps
    }
  },
  getBorrowerLoanAppJson: (state, getters) => {
    if (!getters.getLoanApp?.loan_app_json) return null
    return JSON.parse(getters.getLoanApp.loan_app_json)
  },
  getShowNotesSidePanel: state => {
    return state.showNotesSidePanel
  },
  getLoanAppNote: state => {
    return state.noteValue
  },
  getUnsavedNoteValue: state => {
    return state.unsavedNoteValue
  },
  getShowChatSidePanel: state => {
    return state.showChatSidePanel
  },
  getAutocompleteEnabled: state => {
    return state.servicerProfile?.feature_setting?.loan_app_address_autocomplete_enabled
  },
  getHideMobileFooter: state => {
    return state.hideMobileFooter
  },
  getOrgAdminEditAllowed: state => {
    return state.orgAdminEditAllowed
  },
  getRedirectQueryParam: state => {
    return state.redirectQueryParam
  },
  getCurrentSearchResults: state => {
    return state.currentSearchResults
  },
  getIsOrderAusModalOpen: state => {
    return state.isOrderAusModalOpen
  },
  getShowRunServiceModal: state => {
    return state.showRunServiceModal
  },
  getActionableServices: (state, getters) => {
    const services = []
    if (getters.getCanRunCredit) services.push(serviceEnums.CREDIT)
    if (getters.getCanRunAus) services.push(serviceEnums.AUS)
    if (getters.getCanGeneratePreApprovalLetter) services.push(serviceEnums.PRE_APPROVAL)
    if (getters.getCanGeneratePreQualLetter) services.push(serviceEnums.PRE_QUAL)
    return services
  },
  getMobileFocusedField: state => {
    return state.mobileFocusedField
  },
  getAusProvider: state => {
    return state.ausProvider
  },
  getLoanAppGuid: state => {
    return state.loanApp?.guid || null
  },
  getLoanApplications: state => {
    return state.loanApplications
  }
}
