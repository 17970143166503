<template>
  <div>
    <sn-side-panel
      :value="value"
      title="Review document"
      :primary-btn-props="primaryBtnProps"
      :secondary-btn-props="secondaryBtnProps"
      :loading="!!loadingDocument"
      @input="$emit('input', $event)"
      @primary-btn-click="handleBtnClick"
      @secondary-btn-click="handleBtnClick"
    >
      <template #subtitleContent>
        <SidePanelSubtitle
          :chip-text="chipText"
          :sub-text="taskCreatedText"
          :completed="taskCompleted"
        />
      </template>
      <LoanInfoHeader :task="task" />
      <div class="pa-6">
        <div
          v-if="!taskCompleted"
          class="sn-body-2 mb-6"
        >
          Document is ready for review:
        </div>
        <div class="doc-border pa-4 d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <div class="circle">
              <v-icon
                size="25px"
                color="snPrimary"
              >
                $snDocumentPdf
              </v-icon>
            </div>
            <div class="px-6">
              <div class="sn-body-1 pb-2">
                {{ documentName }}
              </div>
              <div class="sn-body-2 sn-text-primary-01">
                {{ documentSubtitle }}
              </div>
            </div>
          </div>
          <sn-btn
            text="View"
            type="text"
            size="standard"
            @click="onView"
          />
        </div>
      </div>
    </sn-side-panel>
    <LoanDocPreview
      v-if="showLoanDoc"
      v-model="showLoanDoc"
      :document="document"
      @doc-status-updated="onCloseOrUpdateDoc"
      @input="onCloseOrUpdateDoc"
      @refetch-loan-docs="refreshDoc"
    />
  </div>
</template>

<script>
import SidePanelSubtitle from '../SidePanelSubtitle'
import LoanInfoHeader from '../LoanInfoHeader'
import LoanDocPreview from '~/components/loanOfficerApp/loanDetails/tasks/taskTypes/loanDocFolder/LoanDocPreview'
import { loanDocQuery } from '~/components/loanOfficerApp/dashboard/gql/dashboardQueries.gql'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'DocumentSidePanel',
  components: {
    SidePanelSubtitle,
    LoanInfoHeader,
    LoanDocPreview
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    task: {
      type: Object,
      default: () => {}
    },
    selectedLoId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      loadingDocument: 0,
      loadingMutation: false,
      showLoanDoc: false
    }
  },
  computed: {
    ...mapGetters('Dashboard', { servicerId: 'getServicerProfileId' }),
    chipText () {
      return this.task?.resolvedAt ? `Completed on ${this.$moment(this.task?.resolvedAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.resolvedAt).format('h:mm A')}` : 'Action needed'
    },
    taskCreatedText () {
      return this.task?.createdAt ? `Created on ${this.$moment(this.task?.createdAt).format('MMMM DD, YYYY')} at ${this.$moment(this.task?.createdAt).format('h:mm A')}` : ''
    },
    taskCompleted () {
      return !!this.task?.resolvedAt
    },
    primaryBtnProps () {
      return {
        text: this.taskCompleted ? '' : 'Mark as complete',
        loading: this.loadingMutation
      }
    },
    secondaryBtnProps () {
      return {
        text: this.taskCompleted ? 'Mark as incomplete' : '',
        loading: this.loadingMutation
      }
    },
    documentName () {
      return this.document?.name
    },
    documentSubtitle () {
      return `Uploaded on ${this.$moment(this.document?.uploaded_at).format('MMMM DD, YYYY')} by ${this.document?.uploaded_by.full_name}`
    }
  },
  methods: {
    ...mapMutations(['setSelectedLoan']),
    ...mapActions('Dashboard', ['markTasksComplete']),
    async handleBtnClick (showToast) {
      this.loadingMutation = true
      const servicerId = this.selectedLoId === '0' ? this.servicerId : this.selectedLoId
      await this.markTasksComplete({
        guids: [this.task.guid],
        completed: !this.task?.resolvedAt,
        servicerId: servicerId,
        showToast
      })
      this.$emit('reload-tasks')
      this.loadingMutation = false
    },
    onCloseOrUpdateDoc () {
      this.showLoanDoc = false
      if (!this.taskCompleted) {
        this.handleBtnClick(true)
      }
    },
    onView () {
      this.setSelectedLoan(this.task.loan)
      this.showLoanDoc = true
    },
    refreshDoc () {
      this.loadingDocument = 0
      this.$apollo.queries.document.refetch()
    }
  },
  apollo: {
    document: {
      query: loanDocQuery,
      fetchPolicy: 'network-only',
      variables () {
        return {
          loanDocGuid: this.task?.task_object_guid
        }
      },
      loadingKey: 'loadingDocument',
      skip () {
        return !this.task?.task_object_guid
      },
      update (data) {
        return data?.loan_doc
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/css/snui';
.doc-border {
  border: 1px solid $sn-gray-03;
  border-radius: 16px;
  word-break: break-word;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 4px;
  background-color: $sn-gray-01;
}

</style>
