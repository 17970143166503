export const POST_MESSAGE_TO_APP = 'POST_MESSAGE_TO_APP'

// Messages
export const LOG = 'log'
export const LOG_EVENT = 'logEvent'
export const OPEN_CUSTOM_LINK = 'openCustomLink'
export const SEND_EMAIL = 'sendEmail'
export const LOADING_HIDE = 'hideLoading'
export const LOADING_SHOW = 'loadingShow'
export const OPEN_1003 = 'open1003'
export const SET_SHOULD_SCROLL = 'setShouldScroll'
export const SHOW_PDF = 'showPDF'
export const SET_NAVIGATION = 'setNavigation'
export const POST_STAT = 'postStat'
export const CLOSE = 'close'
export const TASK_COMPLETE = 'taskComplete'
export const SHARE_DOCUMENT_URL = 'shareDocumentUrl'

// Navigation Blobs
export const SHOW_BACK = 'showBack'
export const SHOW_BACK_TO_TABLE = 'showBackToTable'
export const SHOW_CANCEL = 'showCancel'
export const SHOW_BACK_AND_CHART = 'showBackAndChart'
export const SHOW_MENU = 'showMenu'

// Calculation Stat Events
export const CALC_AFFORDABILITY_FORM = 'CALC_affordability_form'
export const CALC_AFFORDABILITY_RESULTS = 'CALC_affordability_results'
export const CALC_AMORTIZATION_GRAPH = 'CALC_amortization_graph'
export const CALC_CONVENTIONAL_FORM = 'CALC_conventional_form'
export const CALC_CONVENTIONAL_REFI_FORM = 'CALC_conventional_refi_form'
export const CALC_CONVENTIONAL_REFI_RESULTS = 'CALC_conventional_refi_results'
export const CALC_CONVENTIONAL_RESULTS = 'CALC_conventional_results'
export const CALC_FHA_FORM = 'CALC_fha_form'
export const CALC_FHA_REFI_FORM = 'CALC_fha_refi_form'
export const CALC_FHA_REFI_RESULTS = 'CALC_fha_refi_results'
export const CALC_FHA_RESULTS = 'CALC_fha_results'
export const CALC_GET_PRE_QUALIFIED = 'CALC_get_pre_qualified'
export const CALC_JUMBO_FORM = 'CALC_jumbo_form'
export const CALC_JUMBO_REFI_FORM = 'CALC_jumbo_refi_form'
export const CALC_JUMBO_REFI_RESULTS = 'CALC_jumbo_refi_results'
export const CALC_JUMBO_RESULTS = 'CALC_jumbo_results'
export const CALC_LOAN_TYPES_MENU = 'CALC_loan_types_menu'
export const CALC_MAIN_MENU = 'CALC_main_menu'
export const CALC_RURAL_FORM = 'CALC_rural_form'
export const CALC_RURAL_REFI_FORM = 'CALC_rural_refi_form'
export const CALC_RURAL_REFI_RESULTS = 'CALC_rural_refi_results'
export const CALC_RURAL_RESULTS = 'CALC_rural_results'
export const CALC_SAVE_CALCULATION = 'CALC_save_calculation'
export const CALC_SAVED_CALCULATIONS_LIST = 'CALC_saved_calculations_list'
export const CALC_VA_FORM = 'CALC_va_form'
export const CALC_VA_REFI_FORM = 'CALC_va_refi_form'
export const CALC_VA_REFI_RESULTS = 'CALC_va_refi_results'
export const CALC_VA_RESULTS = 'CALC_va_results'
export const CALC_VIEW_AMORTIZATION = 'CALC_view_amortization'
export const CALC_VIEW_PDF = 'CALC_view_pdf'
export const CALC_VIEW_SAVED_CALCULATION = 'CALC_view_saved_calculation'
export const SHORTCUT_CALC = 'SHORTCUT_CALC'
export const LOAD_SAVED_CALCULATION = 'LOAD_SAVED_CALCULATION'

// Custom Form Events
export const POST_PROGRESS_PERCENTAGE = 'postProgressPercentage'

export function postMessageToApp (route, payload = '') {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[route]) {
    window.webkit.messageHandlers[route].postMessage(payload)
  } else if (window.webkit && window.webkit[route]) {
    window.webkit[route](JSON.stringify(payload))
  } else {
    // Uncomment this log statement to debug on web.
    // console.log(`Posting message to app:
    // Route: ${route}
    // Payload: ${JSON.stringify(payload)}`)
  }
}

// App Message Posting Function
export default {
  methods: {
    postMessageToApp
  }
}
